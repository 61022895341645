<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <section class="content-home border-bottom-red">
        <div class="container-fluid">
            <div class="row">
                <div class="col-2">
                    <div class="content-block">
                        <img class="icon-red img-left" src="../../assets/images/budaya.png" alt="icon">
                    </div>
                </div>

                <div class="col-10">
                    <div class="content-block">
                        <h3> COMPANY <span class="alternate">Culture</span> </h3>

                        <img src="../../assets/images/logo_AKHLAK.jpg" class="mt-3 mb-3 img-fluid">
                        <p>As stipulated in the Circular Letter of the Minister of SOEs Number: SE 7/MBU/07/2020 dated July 1, 2020 regarding the Core Values ​​of Human Resources of State-Owned Enterprises, all SOEs including PT Pelayaran Bahtera Adhiguna MUST apply these values. The Main Value of AKHLAK is the Company's Culture. AKHLAK is an acronym for:</p>

                        <ol>
                            <li>AMANAH (TRUST)</li>
                            <li>KOMPETEN (COMPETENT)</li>
                            <li>HARMONIS (HARMONIOUS)</li>
                            <li>LOYAL (LOYAL)</li>
                            <li>ADAPTIF (ADAPTIVE)</li>
                            <li>KOLABORATIF (COLLABORATIVE)</li>
                        </ol>

                        <div class="flex-container">
                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/about_us">
                                        <img src="../../assets/images/sejarah.png" width="50" height="50" alt="icon">
                                        <p class="text-white">HISTORY</p>
                                    </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                        <router-link to="/vision_mission">
                                            <img src="../../assets/images/visi_misi.png" width="50" height="50" alt="icon">
                                            <p class="text-white">VISION - MISSION</p>
                                        </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-red">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/culture">
                                        <img src="../../assets/images/budaya.png" width="50" height="50" alt="icon">
                                        <p class="text-white">CULTURE</p>
                                    </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/management">
                                        <img src="../../assets/images/manajemen.png" width="50" height="50" alt="icon">
                                        <p class="text-white">MANAGEMENT</p>
                                    </router-link>
                                </div>
                            </div>

                            <div class="flex-item bg-blue">
                                <div class="d-flex justify-content-center align-items-center mt-3">
                                    <router-link to="/governance">
                                        <img src="../../assets/images/tata_kelola.png" width="50" height="50" alt="icon">
                                        <p class="text-white">GOVERNANCE</p>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageCulture',
    components: {
        Banner
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
        }
    },
    mounted () {
        this.loadBanner()
    },
    methods: {
        loadBanner () {
            axios.get('budaya_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_en
                })
        }
    }
}
</script>

<style scoped>
    .content-home {
        position: relative;
    }

    .content-home .content-block {
    padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .image-block {
        position: absolute;
        right: 5%;
        bottom: 0;
    }

    @media (max-width: 767px) {
        .content-home .image-block {
            display: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    span.alternate {
        color: #ea2e23;
        font-weight: 800;
    }

    .flex-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .flex-container div.flex-item {
        flex: 0 0 180px;
        margin: 2px 2px;
    }

    .bg-blue {
        background-color: #0158a7;
    }

    .bg-red {
        background-color: #ea2e23;
    }

    .flex-container div.flex-item div.d-flex a {
        text-decoration: none;
    }
</style>